.page-wrap {
  box-sizing: border-box;
  display: flex;
  width: 510px;
  padding: 50px;
  justify-content: center;
  align-items: center;
  gap: 30px;
  border-radius: 8px;
  border: 1px solid #e6ddf9;
  background: #fff;
  box-shadow: 2px 2px 10px 0px rgba(0, 0, 0, 0.07);
  margin-top: 50px;

  position: relative;

  .checkmark {
    width: 120px;
    height: 120px;
    background-color: #f7f9fc;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .powered-by {
    color: var(--Text-disabled, #7f858b);
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    position: absolute;
    bottom: -55px;
    right: 0;
  }

  .spacer {
    height: 30px;
  }
}

.demo-payment-success-page {
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;
  h1 {
    margin: 0;
    color: var(--Text-header-primary, #2b253a);
    text-align: center;

    /* Headline 4 */
    font-family: Inter;
    font-size: var(--Fonts-Size-Headline-4, 24px);
    font-style: normal;
    font-weight: 600;
    line-height: var(--Fonts-Line-height-Headline-4, 36px); /* 150% */
  }

  h2 {
    margin: 10px 0 00;
    color: var(--Text-header-primary, #2b253a);
    text-align: center;

    /* Paragraph 3 - Regular */
    font-family: Inter;
    font-size: var(--Fonts-Size-Paragraph-3, 16px);
    font-style: normal;
    font-weight: 400;
    line-height: var(--Fonts-Line-height-Paragraph-3, 24px); /* 150% */
    letter-spacing: -0.16px;
  }
}
