.page-wrap {
  box-sizing: border-box;
  display: flex;
  width: 510px;
  padding: 40px;
  justify-content: center;
  align-items: center;
  gap: 30px;
  border-radius: 8px;
  border: 1px solid #e6ddf9;
  background: #fff;
  box-shadow: 2px 2px 10px 0px rgba(0, 0, 0, 0.07);
  margin-top: 50px;

  position: relative;

  .block-centered {
    display: flex;
    justify-content: center;
  }

  .powered-by {
    color: var(--Text-disabled, #7f858b);
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    position: absolute;
    bottom: -55px;
    right: 0;
  }
}

.choose-page {
  width: 100%;
  h1 {
    margin: 0;
    color: var(--Text-text-header-primary, #2b253a);
    text-align: center;

    /* Headline 4 */
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 36px; /* 150% */
  }
}

.choose-page-item {
  &:first-child {
    margin-top: 40px;
  }

  width: 100%;
  // height: 84px;
  padding: 10px;
  display: grid;
  grid-template-columns: 60px 1fr 20px;
  grid-template-rows: 30px 30px;
  gap: 0 20px;
  transition: background-color 0.3s ease;
  margin-bottom: 10px;
  cursor: pointer;
  border-radius: var(--Radius-m, 8px);
  border: var(--Radius-none, 1px) solid var(--Stroke-stroke-primary, #e9e7ee);
  background: var(--Background-bg-primary, #fff);
  box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.05);

  &:hover {
    background-color: var(--Primary, #efeff186);
    box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.05);
  }

  &__graphic {
    grid-column: 1;
    grid-row: 1 / 3;
    align-self: center;
    justify-self: center;
  }

  &__graphic {
    width: 60px;
    height: 60px;

    & > svg {
      width: 60px;
      height: 60px;
    }
  }

  &__title {
    padding-top: 5px;
    grid-column: 2;
    grid-row: 1;
    align-self: center;
    justify-self: start;

    color: var(--Text-text-header-primary, #2b253a);

    /* Headline 5 */
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 26px; /* 144.444% */
  }

  &__subtitle {
    grid-column: 2;
    grid-row: 2;
    align-self: center;
    justify-self: start;

    color: var(--Text-text-secondary, #6c7176);

    /* Paragraph 4 - Regular */
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
  }

  &__arrow {
    grid-column: 3;
    grid-row: 1 / 3;
    align-self: center;
    justify-self: end;
    background-color: #f8f7f6;
    border-radius: var(--Spacing-xs, 8px);
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      transform: scale(1.2);
    }
  }
}
