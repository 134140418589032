.page-wrap {
  box-sizing: border-box;
  display: flex;
  width: 510px;
  padding: 40px;
  justify-content: center;
  align-items: center;
  gap: 30px;
  border-radius: 8px;
  border: 1px solid #e6ddf9;
  background: #fff;
  box-shadow: 2px 2px 10px 0px rgba(0, 0, 0, 0.07);
  margin-top: 50px;

  position: relative;

  .block-centered {
    display: flex;
    justify-content: center;
  }

  .powered-by {
    color: var(--Text-disabled, #7f858b);
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    position: absolute;
    bottom: -55px;
    right: 0;
  }

  .spacer {
    height: 30px;
  }
}

.provide-phone-page {
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  width: 100%;
  h1 {
    width: 100%;
    text-align: center;
    margin: 0;
    color: var(--Text-text-header-primary, #2b253a);
    text-align: center;

    /* Headline 4 */
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 36px; /* 150% */
  }

  p {
    margin: 0;
    color: var(--Text-header-primary, #2b253a);

    /* Paragraph 4 - Regular */
    font-family: Inter;
    font-size: var(--Fonts-Size-Paragraph-4, 14px);
    font-style: normal;
    font-weight: 400;
    line-height: var(--Fonts-Line-height-Paragraph-4, 20px); /* 142.857% */
  }

  label {
    display: block;
    width: 100%;
    margin-bottom: 8px;

    color: var(--Text-text-header-primary, #2b253a);

    /* Paragraph 4 - Medium */
    font-family: Inter;
    font-size: var(--Fonts-Size-Paragraph-4, 14px);
    font-style: normal;
    font-weight: 500;
    line-height: var(--Fonts-Line-height-Paragraph-4, 20px); /* 142.857% */
  }

  input {
    box-sizing: border-box;
    width: 100%;
    height: 40px;
    padding-left: 16px;
    gap: 8px;
    border-radius: 4px;
    border: 1px solid #b7bdc6;
    background: #fff;

    &.error-class {
      border: 1px solid red;
    }
  }

  button {
    outline: none;
    border: none;
    cursor: pointer;
    width: 200px;
    height: 40px;
    border-radius: 6px;
    background: var(--Button-Primary-background, #5f36bc);

    color: var(--Button-Primary-text, #fff);
    text-align: center;

    /* Paragraph 4 - Medium */
    font-family: Inter;
    font-size: var(--Fonts-Size-Paragraph-4, 14px);
    font-style: normal;
    font-weight: 500;
    line-height: var(--Fonts-Line-height-Paragraph-4, 20px); /* 142.857% */
  }

  div.error-class {
    box-sizing: border-box;
    color: red;
    font-size: 12px;
    margin-top: 5px;
    height: 20px;
    overflow: hidden;
    opacity: 0;

    &.visible {
      opacity: 1;
    }
  }
}
